import React, { useState, useEffect } from "react"
import platform from "platform-detect/os.mjs"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Hero from "@components/Driver/TopHero"
import ForDrivers from "@components/Driver/ForDrivers"
import DriverApp from "@components/Driver/DriverApp"
import DriverNeeds from "@components/Driver/DriverNeeds"
import HowToDriver from "@components/Driver/HowToDriver"
import BottomHero from "@components/Driver/BottomHero"
import DriverFaq from "@components/Driver/DriverFaq"
import Footer from "@components/UI/Footer"
import { HomeDriverPageDataType } from "@typings/requests/home/driver-page"
import "./index.scss"

const HomeDriverPage: React.FC<PageProps<HomeDriverPageDataType>> = props => {
  const {
    allStrapiDriverPage: {
      nodes: [homeDriverData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data

  const [storeLink, setStoreLink] = useState(homeDriverData.googlePlayLink)

  useEffect(() => {
    setStoreLink(
      platform.macos || platform.ios
        ? homeDriverData.appStoreLink
        : homeDriverData.googlePlayLink
    )
  }, [homeDriverData.appStoreLink, homeDriverData.googlePlayLink])

  const {
    topHero,
    forDrivers,
    driverApp,
    driverNeeds,
    howToDriver,
    faqSection,
    bottomHero,
  } = homeDriverData
  return (
    <>
      <SEO
        title={homeDriverData.title}
        description={homeDriverData.description}
        pathname={props.location.pathname}
        lang={homeDriverData.locale}
        image={homeDriverData.metaImage.url}
      />
      <ParallaxProvider>
        <LayoutContainer
          className="partners-home"
          location={props.location}
          lang="es"
        >
          <Hero {...topHero} storeLink={storeLink} />
          <ForDrivers {...forDrivers} storeLink={storeLink} />
          <DriverApp
            {...driverApp}
            appStoreLink={homeDriverData.appStoreLink}
            googlePlayLink={homeDriverData.googlePlayLink}
          />
          <DriverNeeds {...driverNeeds} />
          <HowToDriver {...howToDriver} storeLink={storeLink} />
          <DriverFaq {...faqSection} />
          <BottomHero {...bottomHero} storeLink={storeLink} />
          <div className="fullwidth-footer-container">
            <div className="container">
              <Footer {...footerData} />
            </div>
          </div>
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiDriverPage(filter: { locale: { eq: "es" } }) {
      nodes {
        locale
        metaImage {
          url
        }
        title
        description
        appStoreLink
        googlePlayLink
        topHero {
          title
          buttonTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 448
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        forDrivers {
          title
          text {
            data {
              text
            }
          }
          buttonTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 420
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        driverApp {
          title
          text {
            data {
              text
            }
          }
          storesTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 577
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        driverNeeds {
          title
          text {
            data {
              text
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 420
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        howToDriver {
          title
          text {
            data {
              text
            }
          }
          buttonTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 449
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        faqSection {
          faqItem {
            answer {
              data {
                answer
              }
            }
            question
            id
          }
          title
        }
        bottomHero {
          title
          buttonTitle
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default HomeDriverPage
